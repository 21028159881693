import React, {useEffect,useState} from 'react';
import { useLocation } from 'react-router-dom';
import StickyHeader from './StickyHeader';
import Footer from './Footer';
import logo from '../images/round-logo.png';
import LoadingSpinner from './LoadingSpinner';
const PasswordRecovery = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [token, setToken] = useState(null);
  const [uniqueid, setUniqueid] = useState(null);
  const [type, setType] = useState(null);
  const [allData, setAllData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [verification, setVerification] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [newData, setNewData] = useState(null);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');  
  const [successMessage, setSuccessMessage] = useState('');
  useEffect(()=>{
    if(queryParams){
        const rcvtoken = queryParams.get('token');
        const rcvuniqueid = queryParams.get('uniqueid');
        const rcvtype = queryParams.get('type');
        setToken(rcvtoken);
        setUniqueid(rcvuniqueid);
        setType(rcvtype);
    }else{
        setErrorMessage('Going somewhere?');
    }
  },[queryParams]);

    useEffect(()=>{
        if(token){
            if(uniqueid){
                if(type){
                    setAllData(true);
                }
            }
        }

        
    },[token,uniqueid,type]);
  
    useEffect(()=>{
        if(allData){
            fetchDetails();
        }
    },[allData]);

    const fetchDetails = async () => {
        setIsLoading(true);
        try {
            const dataString = {
                token,
                unique_id: uniqueid,
                user_type: type,
            };
            console.log(dataString);
    
            const response = await fetch('https://access.myservicecircle.app/common/12c_resetPassword_verify.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataString),
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const responseData = await response.json();
            console.log(responseData);
    
            if (responseData.success == 1) {
                setVerification(true);
                setErrorMessage(null);
                setNewData(responseData.data);
            } else if (responseData.success == 2) {
                setErrorMessage(responseData.message || 'Token expired. Please try again.');
            } else {
                setErrorMessage(responseData.message || 'Invalid token or unique ID.');
            }
        } catch (error) {
            console.log(error);
            setErrorMessage('An unexpected error occurred. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };
    

    const verifyPasswordStrength = (password) => {
        const regex = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^*()_+\-=\[\]{};':"\\|,.<>\/?`~])[a-zA-Z0-9!@#$%^*()_+\-=\[\]{};':"\\|,.<>\/?`~]{8,12}$/;
        return regex.test(password);
      };
    
      // Function to handle password change
      const handleChangePassword = async () => {
        if (!verifyPasswordStrength(newPassword)) {
          setErrorMessage(
            'Password must be 8-12 characters long, include at least one letter, one number, and one allowed symbol (!@#$%^*).'
          );
          return;
        }
    
        if (newPassword !== confirmPassword) {
          setErrorMessage('New password and confirm password do not match.');
          return;
        }

        setIsLoading(true);

        try{
            const dataString = {
                token:newData.token,
                unique_id:newData.unique_id,
                user_type:newData.user_type,
                password:newPassword
            }

            const response = await fetch('https://access.myservicecircle.app/common/12d_resetPassword_done.php',{
                method:'POST',
                headers:{
                    'Content-Type' : 'application/json'
                },
                body:JSON.stringify(dataString)
            });

            const responseData = await response.json();

            if(responseData.success == 1){
                setErrorMessage('');
                setSuccessMessage('Password changed successfully!');
            }else{
                setErrorMessage(responseData.message);
            }
        }catch(error){

        }finally{
            setIsLoading(false);
        }
    
       
      };
    
    return (
        <div className="disclaimer-page" style={{ backgroundColor: '#DBDBDB', minHeight: '100vh' }}>
          <StickyHeader />
          <main style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '20px' }}>
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <>
                {verification ? (
                  <div style={{backgroundColor: '#FFF',padding: '20px',borderRadius: '10px',boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',textAlign: 'center',fontFamily: 'Montserrat, sans-serif',maxWidth: '400px',margin: '50px auto',}}>
                  <h4 style={{ color: '#993366', fontWeight: '200', marginBottom: '20px', }} >
                    Please change your password
                  </h4>
                  {!successMessage &&
                    <>
                  <input type="password" placeholder="New Password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} style={{ width: '100%', padding: '10px', marginBottom: '15px', border: '1px solid #ddd', borderRadius: '5px', fontFamily: 'Montserrat, sans-serif', }} />
            
                  <input type="password" placeholder="Confirm New Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} style={{ width: '100%', padding: '10px', marginBottom: '15px', border: '1px solid #ddd', borderRadius: '5px', fontFamily: 'Montserrat, sans-serif', }} />
                  </>
                    }
            
                  {errorMessage && (
                    <p style={{ color: 'red', fontSize: '14px', marginBottom: '15px', fontFamily: 'Montserrat, sans-serif', }} >
                      {errorMessage}
                    </p>
                  )}
            
                  {successMessage && (
                    <p style={{ color: '#4CAF50', fontSize: '14px', marginBottom: '15px', fontFamily: 'Montserrat, sans-serif', }} >
                      {successMessage}
                    </p>
                  )}


                {!successMessage &&
                  <button
                    onClick={handleChangePassword}
                    style={{ width: '100%', backgroundColor: '#FCC100', color: '#FFF', padding: '10px', border: 'none', borderRadius: '5px', fontFamily: 'Montserrat,  sans-serif',  fontSize: '16px', cursor: 'pointer', }} >
                    Change Password
                  </button>
                }

                </div>
                ) : (
                  <>
                    {errorMessage ? (
                      <div style={{ backgroundColor: '#FFF', padding: '20px', borderRadius: '10px', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', textAlign: 'center' }}>
                        <h2 style={{ color: '#993366', fontFamily: 'Montserrat, sans-serif', fontWeight: '600' }}>You are late...</h2>
                        <p style={{ fontFamily: 'Montserrat, sans-serif', color: '#333', fontWeight: '400', lineHeight: '1.5' }}>
                          {errorMessage}
                        </p>
                      </div>
                    ) : (
                      <div style={{ backgroundColor: '#FFF', padding: '20px', borderRadius: '10px', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', textAlign: 'center' }}>
                        <h2 style={{ color: '#FCC100', fontFamily: 'Montserrat, sans-serif', fontWeight: '600' }}>Oops! Something isn't right</h2>
                        <p style={{ fontFamily: 'Montserrat, sans-serif', color: '#333', fontWeight: '400', lineHeight: '1.5' }}>
                        {errorMessage ? errorMessage : ''}
                        </p>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </main>
          <Footer />
        </div>
    );
    
};

export default PasswordRecovery;
