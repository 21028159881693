// src/components/RefundPolicy.js
import React from 'react';
import './RefundPolicy.css'; // Import the CSS file for RefundPolicy component
import StickyHeader from './StickyHeader';
import Footer from './Footer';

const RefundPolicy = () => {
  return (
    <div className="refund-policy-page">
      <StickyHeader />
      <main className="refund-policy-content" style={{paddingBottom:100}}>
        <h1>Refund Policy</h1>
        <p>Effective Date: [20.06.2024]</p>
        <p>At MyServiceCircle.App, we strive to provide our users with reliable and high-quality services. However, we understand that there may be circumstances that require a refund. This Refund Policy outlines the conditions, process, and terms under which refunds will be granted, ensuring transparency and protecting the interests of all parties involved.</p>
        
        <h4>1. Refund Eligibility</h4>
        <p>Refunds are offered under specific conditions to ensure fairness. Please review the eligibility criteria:</p>
        <ul>
          <li>Refunds are available only if services are unavailable for a continuous period equal to or exceeding the user’s subscription eligibility period.</li>
          <li>Refund requests must be made within 15 days from the date of service disruption.</li>
          <li>Proof of service unavailability, including error messages or outage logs, must be provided with the refund request.</li>
        </ul>

        <h4>2. Refund Process</h4>
        <p>Users can request refunds through the following methods:</p>
        <ul>
          <li><strong>Online Help Desk:</strong> Submit a ticket by selecting the payment-related category in the app.</li>
          <li><strong>Email:</strong> Send your request to support@myservicecircle.app with detailed information and proof of purchase.</li>
        </ul>
        <p>All requests will be reviewed within 7 business days. Additional documentation may be requested if needed.</p>

        <h4>3. Refund Method</h4>
        <p>Approved refunds will be processed within 15 days and returned to the original payment method unless otherwise agreed upon. Users are responsible for ensuring their payment details are accurate.</p>

        <h4>4. Transaction Fees</h4>
        <p>A non-refundable transaction fee of 7% will be deducted from the total refund amount to cover processing costs.</p>

        <h4>5. Non-refundable Items</h4>
        <ul>
          <li><strong>App Purchases:</strong> All in-app purchases, including subscription fees and one-time purchases, are non-refundable.</li>
          <li><strong>Subscription Plan Upgrades:</strong> Refunds are not available for plan upgrades. Downgrades are not permitted after upgrading to a higher-tier plan.</li>
          <li><strong>Advertising Module:</strong> Ad campaign purchases are non-refundable and subject to community guideline compliance.</li>
        </ul>

        <h4>6. Redeemable Balance</h4>
        <p>In cases where a Redeemable Balance arises (e.g., when upgrading from a long-term subscription to a short-term subscription):</p>
        <ul>
          <li>The Redeemable Balance will be retained in the user’s account and can only be used for future payments, including:</li>
          <ul>
            <li>Renewals of the main subscription package.</li>
            <li>Purchases or renewals of additional client packs.</li>
          </ul>
          <li>No cash refunds will be issued for Redeemable Balance.</li>
          <li>Users can view and manage their Redeemable Balance under the “My Subscriptions” section in the app/webclient.</li>
        </ul>

        <h4>7. Special Conditions</h4>
        <ul>
          <li>Refunds may be denied if the request fails to meet the eligibility criteria outlined in this policy.</li>
          <li>MyServiceCircle.App reserves the right to amend this policy at any time. Changes will be posted on this page with an updated effective date.</li>
          <li>Users are advised to review the refund policy periodically to stay informed of updates.</li>
        </ul>

        <h4>8. Limitations of Liability</h4>
        <p>MyServiceCircle.App is not liable for delays or failures in processing refunds caused by third-party payment gateways or incorrect user details. Our liability is limited to the amount paid by the user for the disputed service.</p>

        <h4>Contact Us</h4>
        <p>If you have any questions or concerns regarding this refund policy, please contact us at support@myservicecircle.app. Our support team is available to assist you with any inquiries.</p>
        
        <p>By using MyServiceCircle.App, you agree to the terms of this refund policy.</p>
      </main>
      <Footer />
    </div>
  );
};

export default RefundPolicy;
