// src/components/Footer.js
import React from 'react';
import './Footer.css'; // Import the CSS file for Footer component
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-item">
        <Link to="/TermsOfUsage" className="footer-link" style={{fontSize:12}}>Terms of usage</Link>
      </div>
      <div className="footer-item">
        <Link to="/RefundPolicy" className="footer-link" style={{fontSize:12}}>Refund Policy</Link>
      </div>
      <div className="footer-item">
        <Link to="/ShippingAndDelivery" className="footer-link" style={{fontSize:12}}>Shipping</Link>
      </div>
      <div className="footer-item">
        <Link to="/PrivacyPolicy" className="footer-link" style={{fontSize:12}}>Privacy Policy</Link>
       </div>
      <div className="footer-item">
        <Link to="/Disclaimer" className="footer-link" style={{fontSize:12}}>Disclaimer</Link>
      </div>
    </div>
  );
};

export default Footer;
