// src/components/ShippingAndDelivery.js
import React from 'react';
import './Disclaimer.css'; // Import the CSS file for Shipping and Delivery component
import StickyHeader from './StickyHeader';
import Footer from './Footer';

const ShippingAndDelivery = () => {
  return (
    <div className="disclaimer-page">
      <StickyHeader /> {/* Use the StickyHeader component */}
      <main className="disclaimer-content" style={{ paddingBottom: 100 }}>
        <h1>Shipping and Delivery</h1>
        <p>
          Welcome to MyServiceCircle.App. Please review our Shipping and Delivery policy for digital goods and services provided through our platform.
        </p>

        <h4>1. Digital Goods and Services</h4>
        <p>
          MyServiceCircle.App facilitates the purchase and delivery of digital goods and services such as subscriptions, access to premium features, and other digital offerings. As these are non-tangible items, there is no physical shipment involved.
        </p>

        <h4>2. Delivery Timeline</h4>
        <p>
          Upon successful payment, access to digital goods or services will be granted immediately or within a maximum of 24 hours. You will receive an email confirmation with details of your purchase and activation.
        </p>

        <h4>3. Access Issues</h4>
        <p>
          If you experience any issues accessing your purchased services or digital goods, please contact us immediately at support@myservicecircle.app. We will resolve your issue within 2 business days.
        </p>

        <h4>4. Failed Transactions</h4>
        <p>
          In case of failed transactions or unsuccessful payment processing, no access will be granted to the digital goods or services. Please retry the transaction or contact your payment provider for assistance. For unresolved payment issues, contact us at support@myservicecircle.app.
        </p>

        <h4>5. Refund and Cancellation</h4>
        <p>
          As our services are digital in nature, refunds and cancellations are governed by our <a href="/RefundPolicy" style={{ color: '#993366', textDecoration: 'none' }}>Refund Policy</a>. Please review it carefully before making a purchase.
        </p>

        <h4>6. No Physical Delivery</h4>
        <p>
          MyServiceCircle.App only deals in digital services and subscriptions. There are no shipping or physical delivery charges associated with any of our offerings.
        </p>

        <h4>7. Changes to this Policy</h4>
        <p>
          MyServiceCircle.App reserves the right to modify this Shipping and Delivery policy at any time. Changes will be posted on this page, and it is your responsibility to review this policy periodically.
        </p>

        <h4>8. Governing Law</h4>
        <p>
          This policy is governed by the laws of India. Any legal actions or proceedings related to this policy shall be resolved in the courts of Mumbai, Maharashtra, India.
        </p>

        <h4>9. Contact Us</h4>
        <p>
          If you have any questions or concerns about our Shipping and Delivery policy, please contact us at support@myservicecircle.app.
        </p>

        <p>
          By using MyServiceCircle.App, you acknowledge that you have read, understood, and agree to this Shipping and Delivery policy.
        </p>
      </main>
      <Footer />
    </div>
  );
};

export default ShippingAndDelivery;
